// src/components/layout/Layout.tsx
import React from "react";

interface LayoutProps {
    children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className="min-h-screen">
            {children}
        </div>
    );
};